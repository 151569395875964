export function newDocumentoFiscal() {
    return {
        numero: null,
        modelo: null,
        serie: null,
        data_emissao: null,
        valor_total: null,
        cnpj: null,
        razao_social: null,
        inscricao_estadual: null,
        uf: null,
        chave_acesso: null,
        observacao: null,
        company_id: null,
    };
}
