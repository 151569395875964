import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, documentoFiscalStore, mainStore, financeiroCategoriaStore, estoqueStore, fornecedorStore, financeiroStore, financeiroParcelaStore } from '@/store';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
import { newDocumentoFiscal } from '@/interfaces/documentoFiscal';
import { newFornecedor } from '@/interfaces/fornecedor';
import { newFinanceiroContaPagar } from '@/interfaces/financeiro';
import { newFinanceiroParcela } from '@/interfaces/financeiroParcela';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let ImportarXML = class ImportarXML extends Vue {
    constructor() {
        super(...arguments);
        this.xmlFile = '';
        this.loading = false;
        this.fileLoaded = false;
        this.search = '';
        this.xmlEntity = {};
        this.selectedItem = {};
        this.FinanceiroCategoriaSelected = {};
        this.produtosHeaders = [
            {
                text: 'Produto',
                value: 'prod.xProd',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Quantidade',
                value: 'prod.qCom',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Custo',
                value: 'prod.vUnCom',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Subtotal',
                value: 'prod.vProd',
                align: 'left',
                filterable: true,
            },
        ];
    }
    get FinanceiroCategoriasOptions() {
        return financeiroCategoriaStore.FinanceiroCategorias;
    }
    get NFeObservacao() {
        return `${this.xmlEntity.nfeProc.NFe.infNFe.ide.natOp}\n${this.xmlEntity.nfeProc.NFe.infNFe.infAdic.infCpl}`;
    }
    get disableSubmit() {
        if (this.FinanceiroCategoriaSelected) {
            return Object.keys(this.FinanceiroCategoriaSelected).length <= 0;
        }
        return true;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed['Documentos Fiscais']) {
            return userToolStore.userToolsIndexed['Documentos Fiscais'][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed['Documentos Fiscais']) {
            return userToolStore.userToolsIndexed['Documentos Fiscais'][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed['Documentos Fiscais']) {
            return userToolStore.userToolsIndexed['Documentos Fiscais'][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed['Documentos Fiscais']) {
            return userToolStore.userToolsIndexed['Documentos Fiscais'][0].allow_delete;
        }
    }
    xml2json(xml) {
        try {
            let obj = {};
            if (xml.children.length > 0) {
                for (let i = 0; i < xml.children.length; i++) {
                    const item = xml.children.item(i);
                    const nodeName = item.nodeName;
                    if (typeof obj[nodeName] === 'undefined') {
                        obj[nodeName] = this.xml2json(item);
                    }
                    else {
                        if (typeof obj[nodeName].push === 'undefined') {
                            const old = obj[nodeName];
                            obj[nodeName] = [];
                            obj[nodeName].push(old);
                        }
                        obj[nodeName].push(this.xml2json(item));
                    }
                }
            }
            else {
                obj = xml.textContent;
            }
            return obj;
        }
        catch (e) {
            const loadingNotification = {
                content: `Erro ao importar NF-e ${e.message}`,
                showProgress: false,
            };
            mainStore.addNotification(loadingNotification);
        }
    }
    async handleXML(file) {
        this.loading = true;
        this.fileLoaded = false;
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = (evt) => {
            const txt = evt.target.result;
            let parser = null;
            let xmlDoc = null;
            if (window.DOMParser) {
                parser = new DOMParser();
                xmlDoc = parser.parseFromString(txt, 'text/xml');
            }
            else {
                // Internet Explorer
                xmlDoc = new ActiveXObject('Microsoft.XMLDOM');
                xmlDoc.async = false;
                xmlDoc.loadXML(txt);
            }
            this.xmlEntity = this.xml2json(xmlDoc);
        };
        reader.onerror = (evt) => {
            const loadingNotification = {
                content: `Erro ao importar NF-e ${evt}`,
                showProgress: false,
            };
            mainStore.addNotification(loadingNotification);
        };
        this.loading = false;
        this.fileLoaded = true;
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            return;
        }
        const createdDocumentoFiscal = newDocumentoFiscal();
        createdDocumentoFiscal.company_id = mainStore.userProfile.company.id;
        createdDocumentoFiscal.numero = this.xmlEntity.nfeProc.NFe.infNFe.ide.nNF;
        createdDocumentoFiscal.modelo = this.xmlEntity.nfeProc.NFe.infNFe.ide.mod;
        createdDocumentoFiscal.serie = this.xmlEntity.nfeProc.NFe.infNFe.ide.serie;
        createdDocumentoFiscal.data_emissao = new Date(this.xmlEntity.nfeProc.NFe.infNFe.ide.dhEmi).toISOString();
        createdDocumentoFiscal.valor_total =
            +this.xmlEntity.nfeProc.NFe.infNFe.total.ICMSTot.vNF;
        createdDocumentoFiscal.cnpj = this.xmlEntity.nfeProc.NFe.infNFe.emit.CNPJ;
        createdDocumentoFiscal.razao_social = this.xmlEntity.nfeProc.NFe.infNFe.emit.xNome;
        createdDocumentoFiscal.inscricao_estadual = this.xmlEntity.nfeProc.NFe.infNFe.emit.IE;
        createdDocumentoFiscal.uf = this.xmlEntity.nfeProc.NFe.infNFe.emit.enderEmit.UF;
        createdDocumentoFiscal.chave_acesso = this.xmlEntity.nfeProc.protNFe.infProt.chNFe;
        createdDocumentoFiscal.observacao = `${this.xmlEntity.nfeProc.NFe.infNFe.ide.natOp}\n${this.xmlEntity.nfeProc.NFe.infNFe.infAdic.infCpl}`;
        // @ts-ignore
        createdDocumentoFiscal.financeiro_categoria_id = this.FinanceiroCategoriaSelected.id;
        const documentoFiscalId = await documentoFiscalStore.createDocumentoFiscal(createdDocumentoFiscal);
        if (!documentoFiscalId) {
            return;
        }
        const estoqueCreated = { documento_fiscal_id: documentoFiscalId };
        const estoquesCreated = this.xmlEntity.nfeProc.NFe.infNFe.det.map(({ prod }) => {
            // @ts-ignore
            estoqueCreated.prod = prod.cProd;
            // @ts-ignore
            estoqueCreated.quantidade = prod.qCom;
            // @ts-ignore
            estoqueCreated.natureza = this.xmlEntity.nfeProc.NFe.infNFe.ide.natOp;
            return { ...estoqueCreated };
        });
        await estoqueStore.createEstoqueXml(estoquesCreated);
        const cnpj = this.xmlEntity.nfeProc.NFe.infNFe.emit.CNPJ.replace('/', '@');
        let fornecedor = await fornecedorStore.getFornecedorByCnpj(cnpj);
        if (!fornecedor) {
            const fornecedorCreate = newFornecedor();
            fornecedorCreate.nome_fantasia = !this.xmlEntity.nfeProc.NFe.infNFe.emit
                .xFant
                ? ''
                : this.xmlEntity.nfeProc.NFe.infNFe.emit.xFant;
            fornecedorCreate.razao_social = !this.xmlEntity.nfeProc.NFe.infNFe.emit
                .xNome
                ? ''
                : this.xmlEntity.nfeProc.NFe.infNFe.emit.xNome;
            fornecedorCreate.cnpj = this.xmlEntity.nfeProc.NFe.infNFe.emit.CNPJ;
            fornecedorCreate.company_id = mainStore.userProfile.company.id;
            const fornecedorId = await fornecedorStore.createFornecedor(fornecedorCreate);
            fornecedor = await fornecedorStore.getFornecedor(fornecedorId);
        }
        const financeiroCreate = newFinanceiroContaPagar();
        financeiroCreate.data_emissao =
            this.xmlEntity.nfeProc.NFe.infNFe.ide.dhEmi.split('T')[0];
        financeiroCreate.numero_documento =
            this.xmlEntity.nfeProc.NFe.infNFe.cobr ? this.xmlEntity.nfeProc.NFe.infNFe.cobr.fat.nFat : null;
        financeiroCreate.observacao =
            this.xmlEntity.nfeProc.NFe.infNFe.infAdic.infCpl;
        financeiroCreate.valor_integral =
            +this.xmlEntity.nfeProc.NFe.infNFe.total.ICMSTot.vNF;
        financeiroCreate.total_pago = !this.xmlEntity.nfeProc.NFe.infNFe.cobr ?
            +this.xmlEntity.nfeProc.NFe.infNFe.total.ICMSTot.vNF : 0;
        financeiroCreate.company_id = mainStore.userProfile.company.id;
        // @ts-ignore
        financeiroCreate.financeiro_categoria_id = this.FinanceiroCategoriaSelected.id;
        financeiroCreate.documento_fiscal_id = documentoFiscalId;
        financeiroCreate.fornecedor_id = fornecedor.id;
        financeiroCreate.numero_parcelas = this.xmlEntity.nfeProc.NFe.infNFe.cobr ? (() => {
            const val = this.xmlEntity.nfeProc.NFe.infNFe.cobr.dup;
            if (Array.isArray(val)) {
                return val.length;
            }
            return 1;
        })() : 1;
        const financeiroId = await financeiroStore.createFinanceiro(financeiroCreate);
        const createdFinanceiroParcela = newFinanceiroParcela();
        const createdFinanceiroParcelas = (() => {
            if (!this.xmlEntity.nfeProc.NFe.infNFe.cobr) {
                createdFinanceiroParcela.financeiro_id = financeiroId;
                createdFinanceiroParcela.parcela_numero = 1;
                createdFinanceiroParcela.status = 1;
                createdFinanceiroParcela.vencimento = this.xmlEntity.nfeProc.NFe.infNFe.ide.dhEmi.split('T')[0];
                createdFinanceiroParcela.valor = +this.xmlEntity.nfeProc.NFe.infNFe.total.ICMSTot.vNF;
                createdFinanceiroParcela.valor_pago = +this.xmlEntity.nfeProc.NFe.infNFe.total.ICMSTot.vNF;
                createdFinanceiroParcela.company_id =
                    mainStore.userProfile.company.id;
                return [createdFinanceiroParcela];
            }
            const val = this.xmlEntity.nfeProc.NFe.infNFe.cobr.dup;
            if (Array.isArray(val)) {
                return val.map(({ nDupl, dVenci, vDupl }) => {
                    createdFinanceiroParcela.financeiro_id = financeiroId;
                    createdFinanceiroParcela.parcela_numero = nDupl;
                    createdFinanceiroParcela.vencimento = new Date(dVenci).toISOString();
                    createdFinanceiroParcela.valor = vDupl;
                    createdFinanceiroParcela.company_id =
                        mainStore.userProfile.company.id;
                    return { ...createdFinanceiroParcela };
                });
            }
            const { nDup, dVenc, vDup } = val;
            createdFinanceiroParcela.financeiro_id = financeiroId;
            createdFinanceiroParcela.parcela_numero = nDup;
            createdFinanceiroParcela.vencimento = new Date(dVenc).toISOString();
            createdFinanceiroParcela.valor = vDup;
            createdFinanceiroParcela.company_id = mainStore.userProfile.company.id;
            return [{ ...createdFinanceiroParcela }];
        })();
        await financeiroParcelaStore.createFinanceiroParcelas(createdFinanceiroParcelas);
        // @ts-ignore
        this.$router.back();
        return;
    }
    async mounted() {
        this.loading = true;
        this.FinanceiroCategoriaSelected = null;
        this.loading = false;
    }
};
ImportarXML = __decorate([
    Component({
        components: {
            DateFieldComponent,
            ValidationObserver,
            ValidationProvider,
        },
    })
], ImportarXML);
export default ImportarXML;
