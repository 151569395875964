export function newFinanceiroParcela() {
    return {
        financeiro_id: null,
        parcela_numero: null,
        valor: null,
        vencimento: null,
        company_id: null,
        restante: null,
        aluno_id: null,
        desconto_percentual: 0,
        valor_pago: 0,
        status: 0,
        fornecedor_id: 0,
        colaborador_id: 0,
        data_pagamento: null,
    };
}
